(function() {
    'use strict';

    angular.module("aerosApp")
        .controller("linkMainController", linkMainController);

    linkMainController.$inject = ['$scope', '$state', '$rootScope', 'routesConstant', 'rolesConstant',
        'aflPageInstructionsModal', 'SessionService'];

    function linkMainController($scope, $state, $rootScope, routesConstant, rolesConstant, aflPageInstructionsModal,
                               SessionService) {
        var LINK = $scope.LINK = routesConstant.LINK;
        $scope.LOGIN = routesConstant.LOGIN;
        $scope.ADMIN = routesConstant.ADMIN;
        $scope.CUSTOMER = routesConstant.CUSTOMER;
        $scope.MFG = routesConstant.MFG;

        $scope.rolesConstant = rolesConstant;

        angular.extend($scope, {
            showInstructions: showInstructions
        });

        (function init() {
            if ($state.current.name === LINK.DASHBOARD.stateName) {
                $state.go(LINK.DASHBOARD.stateName);
            }

            var sessionInfo = SessionService.getSessionInfo();
            $scope.userFullName = sessionInfo.user.fullName;

            // These settings are related to the portal switching options in the user menu.
            // They define what portals the user has access to and the current portal the user is on.
            $scope.hasAdminPortalRole = $scope.hasMfgPortalRole = $scope.hasCustomerPortalRole = false;
            var appAccess = SessionService.getAppAccess();
            if (appAccess) {
                _.each(appAccess, function (access) {
                    if (access === rolesConstant.ADMIN) {
                        $scope.hasAdminPortalRole = true;
                    } else if (access === rolesConstant.MFG) {
                        $scope.hasMfgPortalRole = true;
                    } else if (access === rolesConstant.CUSTOMER) {
                        $scope.hasCustomerPortalRole = true;
                    }
                })
            }
            $scope.role = rolesConstant.LINK;
        })();

        function showInstructions () {
            aflPageInstructionsModal.open($rootScope.appInfo.aerosUserGuideUrl);
        }
    }
})();
